import React, { useEffect, useState } from "react"
import * as actions from "../../redux/actions"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import { BallTriangle as Loading } from "../../components/Common/loaders"
import { Eye, EyeSlash } from "../../components/Common/fontawesome"
import Button from "../../components/Button"
import { Form, EyeHolder } from "../../components/Form/StyledParts"
import { Danger as Err, Success } from "../../components/Form/Alerts"

const ResetPassword = ({ resetSuccess, loading, location, Reset }) => {
  const [isMasked, setIsMasked] = useState(true)
  const [resetError, setResetError] = useState("")
  const { register, handleSubmit, errors, setValue } = useForm()

  useEffect(() => {
    setValue("password", "")
    setValue("passwordConfirm", "")
  }, [])

  const onSubmit = async data => {
    try {
      const { password } = data
      // need to grab token from url
      const token = location.search.split("?token=")[1]

      Reset(password, token)
    } catch (error) {
      console.log(`error: ${error}`)
      setResetError("Error on reset")
    }
  }
  const renderError = () => {
    if (resetError) {
      return <Err>{resetError}</Err>
    } else if (resetSuccess) {
      return <Success>{resetSuccess}</Success>
    }
  }
  const renderForm = () => {
    if (loading) {
      return (
        <div className="row">
          <div className="col-12 text-center">
            <Loading color={`#ff0000`} size={`75`} />
          </div>
        </div>
      )
    }
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12">
            <input
              type={isMasked ? "password" : "text"}
              placeholder="Password"
              name="password"
              ref={register({
                required: "Password is required",
                maxLength: 100,
              })}
            />
            {isMasked ? (
              <EyeHolder>
                <Eye width={24} onClick={() => setIsMasked(!isMasked)} />
              </EyeHolder>
            ) : (
              <EyeHolder>
                <EyeSlash width={24} onClick={() => setIsMasked(!isMasked)} />
              </EyeHolder>
            )}
          </div>
          <div className="col-12">
            {errors.password && <Err>{errors.password.message}</Err>}
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <Button onClick={handleSubmit(onSubmit)}>Reset Password</Button>
          </div>
          <div className="col-12 text-center">{renderError()}</div>
        </div>
      </Form>
    )
  }
  return (
    <Layout>
      <SEO title="Dixie Bracket | Reset Password" />
      <div className="row">
        <div className="col-12 text-center py-3">
          <h2>Reset your password</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mr-auto ml-auto">
          <div className="container-fluid">{renderForm()}</div>
        </div>
      </div>
    </Layout>
  )
}

const MapStateToProps = state => {
  return {
    loading: state.auth.loading,
    resetSuccess: state.auth.resetSuccess,
  }
}

export default connect(MapStateToProps, actions)(ResetPassword)
